/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Button,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  Checkbox,
  PageHeader,
  Space,
  Modal
} from "antd";

const layout = {
  labelCol: {
    span: 16
  },
  wrapperCol: {
    span: 16
  }
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16
  }
};

const documentList = [
  {
    title: "Passbook",
    value: "Passbook"
  },
  {
    title: "voter ID card",
    value: "voter ID card"
  },
  {
    title: "Aadhaar card",
    value: "Aadhaar card"
  },
  {
    title: "PAN card",
    value: "PAN card"
  },
  {
    title: "mobile bill",
    value: "mobile bill"
  },
  {
    title: "Driving license",
    value: "Driving license"
  },
  {
    title: "Rent agreement",
    value: "Rent agreement"
  },
  {
    title: "Electricity bill",
    value: "Electricity bill"
  },
  {
    title: "Income Tax assessment order",
    value: "Income Tax assessment order"
  },
  {
    title: "Birth certificate ",
    value: "Birth certificate "
  }
];

export default function Index() {
  const handleMultipleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const [addFeesModel, setAddFeesModel] = React.useState(false);
  const showAddFeesModel = () => {
    setAddFeesModel(true);
  };
  const hideAddFeesModel = () => {
    setAddFeesModel(false);
  };
  return (
    <div>
      <Modal
        title="Add Fee"
        visible={addFeesModel}
        onOk={hideAddFeesModel}
        onCancel={hideAddFeesModel}
      >
        <div className="tw-px-8">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Form.Item label="Fee Type" name="Fee Type">
              <Select defaultValue="Processing Fee">
                <Select.Option value="Processing Fee">
                  Processing Fee
                </Select.Option>
                <Select.Option value="Subvention Fee">
                  Subvention Fee
                </Select.Option>
                <Select.Option value="Delay Fee">Delay Fee</Select.Option>
                <Select.Option value="Other Fee">Other Fee</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Fee Amount" name="Fee Amount">
              <Checkbox>Checkbox</Checkbox>
            </Form.Item>
            <Form.Item
              label="Fee to be added or deducted"
              name="Fee to be added or deducted"
            >
              <Switch
                checkedChildren="Added"
                unCheckedChildren="Deducted"
                defaultChecked
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <PageHeader
        className="site-page-header"
        title="Portfolio"
        subTitle="Edit Portfolio"
      />
      <div className="tw-pt-8 tw-border-2 tw-border-[#f0f0f0]">
        <Form
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 12
          }}
          layout="horizontal"
          size="md"
        >
          <Form.Item label="Name">
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item label="Unique ID">
            <Input placeholder="Enter Unique ID" />
          </Form.Item>
          <Form.Item label="Select Product Type">
            <Select placeholder="Select Product Type">
              <Select.Option value="Personal Loan">Personal Loan</Select.Option>
              <Select.Option value="Micro Loan">Micro Loan</Select.Option>
              <Select.Option value="Consumer Loan">Consumer Loan</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Checks">
            <Select
              placeholder="Select Checks"
              mode="multiple"
              onChange={handleMultipleChange}
            >
              <Select.Option value="Disbursement by Apollo">
                Disbursement by finstack
              </Select.Option>
              <Select.Option value="Disbursement to third-party">
                Disbursement to third-party
              </Select.Option>
              <Select.Option value="Restructuring allowed">
                Restructuring allowed
              </Select.Option>
              <Select.Option value=" Pre EMI calculation required">
                Pre EMI calculation required
              </Select.Option>
              <Select.Option value="Repayment by NACH">
                Repayment by NACH
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Fees">
            <Button type="primary" onClick={() => showAddFeesModel()}>
              Add Fees
            </Button>
          </Form.Item>
          <Form.Item label="Required Documents">
            <Select
              placeholder="Select Required Documents"
              mode="multiple"
              onChange={handleMultipleChange}
            >
              {documentList.map((document, index) => {
                return (
                  <Select.Option value={document.value}>
                    {document.title}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Required Customer Attributes">
            <Select
              placeholder="Select All Required Customer Attributes for  vertification"
              mode="multiple"
              onChange={handleMultipleChange}
            >
              <Select.Option value="First Name">First Name</Select.Option>
              <Select.Option value="Last Name">Last Name</Select.Option>
              <Select.Option value="Gender">Gender</Select.Option>
              <Select.Option value="Email">Email</Select.Option>
              <Select.Option value="DOB">Date of Birth</Select.Option>
              <Select.Option value="Address">Address</Select.Option>
              <Select.Option value="City">City</Select.Option>
              <Select.Option value="State">State</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button htmlType="button">Reset</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
