import React, { useState } from "react";
import {
  EditOutlined,
  DatabaseOutlined,
  FolderViewOutlined,
  MoneyCollectOutlined,
  TransactionOutlined
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Breadcrumb, Layout, Menu } from "antd";
import configFile from "../../Utils/configFile.json";

const { Header, Content, Footer, Sider } = Layout;

export default function DashboardLayout({ children }) {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Layout
      style={{
        height: "100vh"
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="tw-h-12 tw-m-2 tw-mb-8">
          <div className="tw-flex tw-items-center tw-justify-between tw-h-12">
            <img
              src={configFile.logo}
              alt="logo"
              className="tw-mx-auto tw-h-12 "
            />
          </div>
        </div>
        <Menu
          theme="dark"
          // defaultSelectedKeys={["1"]}
          mode="inline"
          // items={items}
          className="tw-pt-16"
        >
          <Menu.SubMenu
            key="Portfolio"
            title="Portfolio"
            icon={<DatabaseOutlined />}
          >
            <Menu.Item key="1" icon={<FolderViewOutlined />}>
              <Link to="/portfolio">View All Portfolio</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<EditOutlined />}>
              <Link to="/portfolio/new"> Add New Portfolio</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key="Disbursement"
            title="Disbursement"
            icon={<MoneyCollectOutlined />}
          >
            <Menu.Item key="3">
              <Link to="/disbursement/upload-loan-file">
                Upload Loan creation file
              </Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/disbursement/upload-nach-file">
                Upload NACH Approval file
              </Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/disbursement/nach-file-download">
                Download file for NACH processing
              </Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Link to="/disbursement/file-download">
                Download Disbursement File
              </Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Link to="/disbursement/upload">Upload disbursement status</Link>
            </Menu.Item>
            <Menu.Item key="8">
              <Link to="/disbursement/loan-download">
                Download Loan Information
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key="Repayments"
            title="Repayments"
            icon={<TransactionOutlined />}
          >
            <Menu.Item key="9">
              <Link to="/repayment/emi-report-download">
                Download Expected EMI Due Report
              </Link>
            </Menu.Item>
            <Menu.Item key="10">
              <Link to="/repayment/nach-file-download">
                NACH Initiation File
              </Link>
            </Menu.Item>
            <Menu.Item key="11">
              <Link to="/repayment/upload-emi-status">
                Upload NACH EMI Status File
              </Link>
            </Menu.Item>
            <Menu.Item key="12">
              <Link to="/repayment/upload-repayment-file">
                Upload Repayments file
              </Link>
            </Menu.Item>
            <Menu.Item key="13">
              <Link to="/repayment/dpd-report-download">
                Download DPD Report
              </Link>
            </Menu.Item>
            <Menu.Item key="14">
              <Link to="/repayment/bureau-file-download">
                Download Bureau Reporting file
              </Link>
            </Menu.Item>
            <Menu.Item key="15">
              <Link to="/repayment/upload-restructure">
                Upload Restructure Request
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
          {/* <Menu.SubMenu
            key="SubMenu"
            title="Portfolio"
            icon={<DatabaseOutlined />}
          >
            <Menu.Item key="two" icon={<FolderViewOutlined />}>
              View All Portfolio
            </Menu.Item>
            <Menu.Item key="three" icon={<EditOutlined />}>
              Add New Portfolio
            </Menu.Item>
          </Menu.SubMenu> */}
        </Menu>
      </Sider>
      <Layout className="tw-bg-white tw-pt-8 tw-overflow-hidden">
        <Content className="tw-overflow-scroll tw-px-8">
          <div className="tw-bg-white tw-min-h-[80vh] ">{children}</div>
        </Content>
        <Footer
          style={{
            textAlign: "center"
          }}
        >
          {configFile.footerText}
        </Footer>
      </Layout>
    </Layout>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired
};
