/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { PageHeader, Upload, Form, Input, Space, Button, Select } from "antd";
import { InboxOutlined } from "@ant-design/icons";

export default function UploadLoanFile() {
  const [Scheme, setScheme] = React.useState("");
  const [LoanType, setLoanType] = React.useState("");
  let showMisUpload = false;
  React.useEffect(() => {
    showMisUpload = Scheme && LoanType;
  }, [Scheme, LoanType]);
  console.log(showMisUpload);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 }
  };
  return (
    <div className=" tw-p-4 tw-border-[1px]">
      <PageHeader
        className="site-page-header"
        title="Upload Loan creation file"
      />
      <Form {...layout}>
        <Form.Item label="Scheme">
          <Select placeholder="Enter Scheme">
            <Select.Option value=" Gyandhan"> Gyandhan</Select.Option>
            <Select.Option value="Razorpay">Razorpay</Select.Option>
            <Select.Option value="GoRupee">GoRupee</Select.Option>
            <Select.Option value="Instamojo">Instamojo</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Product Type">
          <Select placeholder="Loan Type">
            <Select.Option value=" PL"> PL</Select.Option>
            <Select.Option value="CL">CL</Select.Option>
            <Select.Option value="ML">ML</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Upload MIS Sheet">
          <Upload.Dragger>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Space size="large">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button htmlType="button">Reset</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
