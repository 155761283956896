import React from "react";
import { PageHeader, Form, Upload, Space, Button } from "antd";
import { InboxOutlined } from "@ant-design/icons";

export default function UploadEMIStatus() {
  return (
    <div className="tw-p-4 tw-m-4">
      <PageHeader
        className="site-page-header"
        title="Upload NACH EMI Status File"
      />
      <div className="tw-p-6">
        <Upload.Dragger>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
      </div>
      <div className="tw-flex tw-items-center tw-justify-center tw-mx-auto">
        <Space size="large">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Space>
      </div>
    </div>
  );
}
