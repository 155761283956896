import React from "react";
import {
  Button,
  Select,
  PageHeader,
  Tabs,
  Table,
  Image,
  List,
  Upload,
  Modal,
  Input,
  Form
} from "antd";

const borrowerData = [
  { name: "FSL ID", value: "AFILGRML1001453" },
  { name: "Partner Loan ID", value: "1001453" },
  { name: "Partner Loan Type Tag", value: "GR_ML" },
  { name: "Loan Type", value: "ML" },
  { name: "Partner Tag", value: "GR" },
  { name: "First Name", value: "Leo" },
  { name: "Last Name", value: "Dcap" },
  { name: "Date Of Birth", value: "24/09/1990" },
  { name: "First Name", value: "Leo" },
  { name: "First Name", value: "Leo" }
];
const loanData = [
  { name: "Amount", value: "" },
  { name: "Disbursement Amount", value: "3000" },
  { name: "Partner Computed EMI Amount", value: "3017" },
  { name: "Partner Computed Interest Amount", value: "17" },
  { name: "Benificiary Code", value: "MXFGZPBMPEBS" },
  { name: "Current Balance", value: "3017" },
  { name: "Risk", value: "not_assessed" },
  { name: "Date Of Birth", value: "24/09/1990" },
  { name: "State", value: "kyc_and_credit_check_pending" }
];

const defaultFileList = [
  {
    uid: "1",
    name: "xxx.png",
    status: "done",
    response: "Server Error 500",
    // custom error message to show
    url: "http://www.baidu.com/xxx.png"
  },
  {
    uid: "2",
    name: "yyy.png",
    status: "done",
    url: "http://www.baidu.com/yyy.png"
  }
];

const emiColumn = [
  {
    title: "EMI Due",
    dataIndex: "EMIDue",
    key: "EMIDue"
  },
  {
    title: "Principal Due",
    dataIndex: "principalDue",
    key: "principalDue"
  },
  {
    title: "Intrest Due",
    dataIndex: "intrestDue",
    key: "intrestDue"
  },
  {
    title: "EMI Due Date",
    dataIndex: "EMIDueDate",
    key: "EMIDueDate"
  },
  {
    title: "Principal O/s",
    dataIndex: "PrincipalOS",
    key: "PrincipalOS"
  },
  {
    title: "EMI Received",
    dataIndex: "EMIReceived",
    key: "EMIReceived"
  },
  {
    title: "Principal Received",
    dataIndex: "PrincipalReceived",
    key: "PrincipalReceived"
  },
  {
    title: "Interest Received",
    dataIndex: "interestReceived",
    key: "interestReceived"
  },
  {
    title: "Date Of Repayment",
    dataIndex: "DateOfRepayment",
    key: "DateOfRepayment"
  },
  {
    title: "Principal O/S Received",
    dataIndex: "principalOSReceived",
    key: "principalOSReceived"
  }
];

const paidHistoryColumn = [
  {
    title: "Amount Received Exclusive Of Charges",
    dataIndex: "AmountReceivedExclusiveCharges",
    key: "AmountReceivedExclusiveCharges"
  },
  {
    title: "Bounce Charges",
    dataIndex: "bounceCharges",
    key: "bounceCharges"
  },
  {
    title: "GST On Bounce Charges",
    dataIndex: "GSTBounceCharges",
    key: "GSTBounceCharges"
  },
  {
    title: "Other Charges",
    dataIndex: "otherCharges",
    key: "otherCharges"
  },
  {
    title: "GST on Other Charges",
    dataIndex: "GSTOtherCharges",
    key: "GSTOtherCharges"
  },
  {
    title: "Moratorium Interest",
    dataIndex: "MoratoriumInterest",
    key: "MoratoriumInterest"
  },
  {
    title: "Penal Interest",
    dataIndex: "penalInterest",
    key: "penalInterest"
  },
  {
    title: "Customer Payback Date",
    dataIndex: "CustomerPaybackDate",
    key: "CustomerPaybackDate"
  },
  {
    title: "Partner Payback Date",
    dataIndex: "PartnerPaybackDate",
    key: "PartnerPaybackDate"
  },
  {
    title: "Repayment Type",
    dataIndex: "repaymentType",
    key: "repaymentType"
  }
];

const emiColumnDATA = [
  {
    EMIDue: "3017",
    principalDue: "3000",
    intrestDue: "17",
    EMIDueDate: "04/08/2020",
    PrincipalOS: "0",
    EMIReceived: "-",
    PrincipalReceived: "-",
    interestReceived: "-",
    DateOfRepayment: "-",
    principalOSReceived: "-"
  }
];

const paidHistoryColumnData = [
  {
    AmountReceivedExclusiveCharges: "",
    bounceCharges: "",
    GSTBounceCharges: "",
    otherCharges: "",
    GSTOtherCharges: "",
    MoratoriumInterest: "",
    penalInterest: "",
    CustomerPaybackDate: "",
    PartnerPaybackDate: "",
    repaymentType: ""
  }
];

const logsColumn = [
  {
    title: "Modified On",
    dataIndex: "modifiedOn",
    key: "modifiedOn"
  },
  {
    title: "Updated By",
    dataIndex: "updatedBy",
    key: "updatedBy"
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "Action"
  }
];

const logsColumnData = [
  {
    modifiedOn: "16/09/2020 13:52:41",
    updatedBy: "pranali",
    Action: "VO"
  },
  {
    modifiedOn: "16/09/2020 13:52:41",
    updatedBy: "pranali",
    Action: "VO"
  },
  {
    modifiedOn: "16/09/2020 13:52:41",
    updatedBy: "pranali",
    Action: "VO"
  },
  {
    modifiedOn: "16/09/2020 13:52:41",
    updatedBy: "pranali",
    Action: "VO"
  },
  {
    modifiedOn: "16/09/2020 13:52:41",
    updatedBy: "pranali",
    Action: "VO"
  }
];

export default function Index() {
  const [riskAssesment, setRiskAssesment] = React.useState(false);
  const [risk, setRisk] = React.useState("");
  const showTRiskAssesment = () => {
    setRiskAssesment(true);
  };
  const hideTRiskAssesment = () => {
    setRiskAssesment(false);
  };
  return (
    <div className="tw-p-6">
      <Modal
        title="FinStack Analysis"
        visible={riskAssesment}
        onOk={hideTRiskAssesment}
        onCancel={hideTRiskAssesment}
      >
        <Form
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            label="Credit Score"
            name="Credit Score"
            rules={[{ required: true, message: "Credit Score Required !" }]}
          >
            <Input placeholder="Credit Score" />
          </Form.Item>
          <Form.Item
            label="FinStack Score"
            name="FinStack Score"
            rules={[{ required: true, message: "FinStack Score Required !" }]}
          >
            <Input placeholder="FinStack Score" />
          </Form.Item>
          <Form.Item
            label="Risk"
            rules={[{ required: true, message: "Select Risk !" }]}
          >
            <Select onChange={(value) => setRisk(value)}>
              <Select.Option value="Low">Low</Select.Option>
              <Select.Option value="Medium">Medium</Select.Option>
              <Select.Option value="High">High</Select.Option>
            </Select>
          </Form.Item>
          {risk === "High" && (
            <Form.Item
              label="Risk Reason"
              rules={[{ required: true, message: "Select Risk Reason !" }]}
            >
              <Select>
                <Select.Option value="FOIR">FOIR</Select.Option>
                <Select.Option value="BANKING">BANKING</Select.Option>
                <Select.Option value="CREDIT BUREAU">
                  CREDIT BUREAU
                </Select.Option>
                <Select.Option value="SALARY">SALARY</Select.Option>
                <Select.Option value="OTHER">OTHER</Select.Option>
              </Select>
            </Form.Item>
          )}
          {/*  */}
          {risk && (
            <Form.Item label="Comments">
              <Input.TextArea />
            </Form.Item>
          )}
          {/*  */}
        </Form>
      </Modal>
      <PageHeader
        ghost={false}
        title="Loan Details"
        extra={[
          <Button key="3" type="primary" danger>
            REJECT
          </Button>,
          <Button key="2" type="primary">
            EDIT LOAN
          </Button>,
          <Button
            key="1"
            type="primary"
            danger
            ghost
            onClick={() => showTRiskAssesment()}
          >
            RISK ASSESMENT
          </Button>,
          <Button key="1">BACK TO DOC RECEIVED</Button>
        ]}
      />
      <div className="tw-m-4 tw-p-4 tw-border-[1px]">
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="BORROWER DETAILS" key="1">
            <List
              dataSource={borrowerData}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta title={item.name} />
                  <List.Item.Meta title={item.value} />
                </List.Item>
              )}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="LOAN DETAILS" key="2">
            <List
              dataSource={loanData}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta title={item.name} />
                  <List.Item.Meta title={item.value} />
                </List.Item>
              )}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
      <div className="tw-m-4 tw-p-4 tw-border-[1px]">
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="EMI LIST" key="1">
            <Table
              columns={emiColumn}
              dataSource={emiColumnDATA}
              pagination={false}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="PAID HISTORY" key="2">
            <Table
              columns={paidHistoryColumn}
              dataSource={paidHistoryColumnData}
              pagination={false}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
      <div className="tw-m-4 tw-p-4 tw-border-[1px]">
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="IMAGES" key="1">
            <Image.PreviewGroup>
              <Image
                width={280}
                height={280}
                className="tw-p-2"
                src="https://res.cloudinary.com/sumit9988/image/upload/v1654825440/78911325_asexm5.webp"
              />
              <Image
                width={280}
                height={280}
                className="tw-p-4"
                src="https://res.cloudinary.com/sumit9988/image/upload/v1654825498/FileSize2_wbcv5u.png"
              />
              <Image
                width={280}
                height={280}
                className="tw-p-4"
                src="https://res.cloudinary.com/sumit9988/image/upload/v1654825440/78911325_asexm5.webp"
              />
              <Image
                width={280}
                height={280}
                className="tw-p-4"
                src="https://res.cloudinary.com/sumit9988/image/upload/v1654825498/FileSize2_wbcv5u.png"
              />{" "}
              <Image
                width={280}
                height={280}
                className="tw-p-4"
                src="https://res.cloudinary.com/sumit9988/image/upload/v1654825440/78911325_asexm5.webp"
              />
              <Image
                width={280}
                height={280}
                className="tw-p-4"
                src="https://res.cloudinary.com/sumit9988/image/upload/v1654825498/FileSize2_wbcv5u.png"
              />
            </Image.PreviewGroup>
          </Tabs.TabPane>
          <Tabs.TabPane tab="ADDITIONAL LINKS" key="2">
            <Upload fileList={defaultFileList} />
          </Tabs.TabPane>
        </Tabs>
      </div>
      <div className="tw-m-4 tw-p-4 tw-border-[1px]">
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Logs" key="1">
            <Table columns={logsColumn} dataSource={logsColumnData} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
}
