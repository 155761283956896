import React from "react";
import { Link } from "react-router-dom";
import { Table, Select, Typography, Space, Button } from "antd";
import data from "../../../../Utils/loan_mock_data.json";
// import loanStage from "../../../../Utils/loan_stage.json";
// import productStage from "../../../../Utils/product_stage.json";

export default function Index() {
  const [loading, setLoading] = React.useState(true);
  const [loanList, setLoanList] = React.useState([]);
  const [selectedItem, setSelectedRowKeys] = React.useState([]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setLoanList(data);
    }, 1000);
  });

  const rowSelection = {
    onChange: (selectedRows) => {
      console.log("selectedRows: ", selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    }
  };
  const hasSelected = selectedItem.length > 0;

  const columns = [
    {
      title: "FSL ID",
      dataIndex: "FSLID",
      width: 300,
      render: (text) => (
        <Link to={`/portfolio/loan/${text}`}>
          <p className="tw-text-blue-400">{text}</p>
        </Link>
      )
    },
    {
      title: "Partner Loan ID",
      dataIndex: "PLID"
    },
    {
      title: "Name",
      dataIndex: "name"
    },
    {
      title: "Loan Amount",
      dataIndex: "amount",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.amount - b.amount
    },
    {
      title: "Stage",
      dataIndex: "stage",
      filters: [
        { text: "Pending", value: "Pending" },
        { text: "Lead Generated", value: "Lead Generated" },
        { text: "Document Check Pending", value: "Document Check Pending" },
        { text: "Credit Double Check", value: "Credit Double Check" },
        {
          text: "Super Admin Loan Approval",
          value: "Super Admin Loan Approval"
        },
        { text: "Super Admin Loan Update", value: "Super Admin Loan Update" },
        { text: "Approval", value: "Approval" },
        { text: "Super Admin Loan", value: "Super Admin Loan" },
        { text: "Restructure Approval", value: "Restructure Approval" },
        { text: "Disbursement Pending", value: "Disbursement Pending" },
        { text: "Failed Loan", value: "Failed Loan" },
        { text: "Loan Ongoing Monitoring", value: "Loan Ongoing Monitoring" },
        { text: "Loan Ongoing", value: "Loan Ongoing" },
        { text: "Loan Complete", value: "Loan Complete" },
        { text: "Super Admin Account", value: "Super Admin Account" },
        { text: "Rejected", value: "Rejected" },
        { text: "NACH Flag", value: "NACH Flag" }
      ],
      onFilter: (value, record) => record.stage.indexOf(value) === 0
    },
    {
      title: "Product type",
      dataIndex: "product_type",
      filters: [
        { text: "Personal Loans", value: "Personal Loans" },
        { text: "Consumer Loans", value: "Consumer Loans" },
        { text: "Micro Loans", value: "Micro Loans" }
      ],
      onFilter: (value, record) => record.product_type.indexOf(value) === 0
    },
    {
      title: "Disbursement Start Date",
      dataIndex: "disbursement_start_date"
    },
    {
      title: "Disbursement End Date",
      dataIndex: "disbursement_end_date"
    }
  ];

  return (
    <div>
      {/* <Row gutter={[24, 24]} wrap={false} className="tw-p-6"> */}
      {/* <Col span={6} className="tw-sticky tw-m-4 tw-h-[1100px]">
        <div className="tw-border-[1px] ">
          <PageHeader
            ghost={false}
            title="Filters"
            extra={[
              <Button type="text" onClick={() => clearFilter()}>
                CLEAR ALL
              </Button>
            ]}
          />
          <div className="tw-border-[1px] tw-p-4 tw-flex tw-gap-1 tw-flex-row tw-flex-wrap">
            {filterTags.map((tag) => {
              return tag && <Tag key={tag}>{tag}</Tag>;
            })}
          </div>
          <Collapse defaultActiveKey={["1", "2", "3"]} ghost>
            <Collapse.Panel
              header={<p className="tw-font-bold tw-text-base ">Products</p>}
              key="1"
            >
              <div className="tw-flex tw-flex-col tw-gap-2 tw-items-start tw-flex-wrap">
                {productStage.map((stage, index) => {
                  return (
                    <Checkbox
                      onChange={(e) => changeProductStage(e, stage)}
                      checked={selectedLoanProductStage === stage}
                    >
                      {stage}
                    </Checkbox>
                  );
                })}
              </div>
            </Collapse.Panel>
            <Collapse.Panel
              header={<p className="tw-font-bold tw-text-base ">Loan Stages</p>}
              key="2"
            >
              <div className="tw-flex tw-flex-col tw-gap-2  tw-flex-wrap">
                {loanStage.map((stage, index) => {
                  return (
                    <Checkbox
                      onChange={(e) => changeLoanStage(e, stage)}
                      checked={selectedLoanStage === stage}
                    >
                      {stage}
                    </Checkbox>
                  );
                })}
              </div>
            </Collapse.Panel>
            <Collapse.Panel
              header={
                <p className="tw-font-bold tw-text-base ">Disbursement Date</p>
              }
              key="3"
            >
              <DatePicker.RangePicker />
            </Collapse.Panel>
          </Collapse>
        </div>
      </Col> */}
      {/* <Col span={16} className=" tw-m-4 tw-h-full tw-overflow-scroll"> */}
      <div className="tw-w-full">
        {hasSelected && (
          <div className="tw-mb-8 tw-p-4 tw-border-[1px] tw-flex tw-flex-col tw-gap-4">
            <Typography.Text className="tw-text-base font-">
              Actions
            </Typography.Text>
            <Space size="middle">
              <Select
                defaultValue="Move to Document Check Pending"
                style={{ width: 340 }}
              >
                <Select.Option value="Move to Document Check Pending">
                  Move to Document Check Pending
                </Select.Option>
                <Select.Option value="  Move to Credit Double Check Pending">
                  Move to Credit Double Check Pending
                </Select.Option>
                <Select.Option value="Move to Rejected">
                  Move to Rejected
                </Select.Option>
              </Select>
              <Button type="primary">PROCEED</Button>
            </Space>
          </div>
        )}

        <Table
          rowSelection={(selectedItem, rowSelection)}
          loading={loading}
          columns={columns}
          dataSource={loanList}
          scroll={{ x: 1500 }}
        />
      </div>
    </div>
  );
}

// {/* </Col> */}
//     {/* // </Row> */}
