import React from "react";
import { PageHeader, Form, Select, Space, Button, DatePicker } from "antd";

export default function DownloadNACHFile() {
  return (
    <div className="tw-p-4 tw-m-4">
      <PageHeader
        className="site-page-header"
        title="Download Loan Information"
      />
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
        <Form.Item label="EMI Date">
          <DatePicker.RangePicker />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Space size="large">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button htmlType="button">Reset</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
