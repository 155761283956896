import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import configFile from "../../../Utils/configFile.json";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [key, setKey] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const onSubmit = (values) => {
    console.log(values);
    navigate("/portfolio");
  };

  return (
    <div className="tw-bg-white">
      <div className="tw-flex tw-items-center tw-justify-center tw-mx-auto tw-pt-40">
        <Form
          name="basic"
          className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mx-auto"
          initialValues={{ remember: true }}
          size="middle"
          autoComplete="off"
          onFinish={onSubmit}
        >
          <Form.Item>
            <img
              src={configFile.logo}
              className="tw-w-20 tw-mx-auto tw-pb-4"
              alt="logo"
            />
            <Typography.Text type="secondary" className="">
              Change Your password!{" "}
            </Typography.Text>
          </Form.Item>

          <Form.Item
            name="password"
            className="tw-w-60"
            rules={[{ required: true, message: "Confirm Your Password!" }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Confirm Your password!"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            className="tw-w-60"
            rules={[
              {
                required: true,
                message: "Please confirm your password!"
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                }
              })
            ]}
          >
            <Input.Password
              onChange={(e) => setConfirmPassword(e.target.value)}
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Please input Your password!"
            />
          </Form.Item>

          <Form.Item>
            <Button className="tw-w-60" type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
