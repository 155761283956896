/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { React } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
// Route For Landing page or Home Page
import Home from "./Views/Home/Index";
// Route For Error Page Like 404 Page Not Found or 500 Internal Server Error
import FourOFour from "./Views/Error/FourOFour";
// Route Related to Auth
import AuthLoginPage from "./Views/Auth/Login/Login";
import AuthSignupPage from "./Views/Auth/Signup/Signup";
import AuthForgetPasswordPage from "./Views/Auth/ForgetPassword/ForgetPassword";
import AuthResetPasswordPage from "./Views/Auth/ResetPassword/ResetPassword";
// Layout For All Routes of Dashboard
import Layout from "./Components/Layout/DashboardLayout";
// Route Page Related to Portfolio in Dashboard
import Portfolio from "./Views/Dashboard/Portfolio/Portfolio/Index";
import PortfolioLoanList from "./Views/Dashboard/Portfolio/LoanList/Index";
import PortfolioNew from "./Views/Dashboard/Portfolio/PortfolioNew/Index";
import PortfolioEdit from "./Views/Dashboard/Portfolio/PortfolioEdit/Index";
import PortfolioLoanDetails from "./Views/Dashboard/Portfolio/Loan/Index";
// Disbursement Page
import DisbursementDownloadFile from "./Views/Dashboard/Disbursement/DownloadDisbursementFile";
import DisbursementNACHFileDownload from "./Views/Dashboard/Disbursement/DownloadFileForNACH";
import DisbursementLoanInfoFileDownload from "./Views/Dashboard/Disbursement/DownloadLoanInfo";
import DisbursementUploadFile from "./Views/Dashboard/Disbursement/UploadDisbursement";
import DisbursementLoanFileUpload from "./Views/Dashboard/Disbursement/UploadLoanFile";
import DisbursementNACHFileUpload from "./Views/Dashboard/Disbursement/UploadNACHFile";
// Repayments Page
import RepaymentsBureauFileDownload from "./Views/Dashboard/Repayments/DownloadBureauFile";
import RepaymentsDPDReportDownload from "./Views/Dashboard/Repayments/DownloadDPDReport";
import RepaymentsEMIReportDownload from "./Views/Dashboard/Repayments/DownloadEMIReport";
import RepaymentsNACHFileDownload from "./Views/Dashboard/Repayments/DownloadNACHFile";
import RepaymentsEMIStatusUpload from "./Views/Dashboard/Repayments/UploadEMIStatus";
import RepaymentsRepaymentFileUpload from "./Views/Dashboard/Repayments/UploadReplaymentFile";
import RepaymentsRestructureFileUpload from "./Views/Dashboard/Repayments/UploadRestructure";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/auth/login" element={<AuthLoginPage />} />
        <Route path="/auth/signup" element={<AuthSignupPage />} />
        <Route
          path="/auth/forget-password"
          element={<AuthForgetPasswordPage />}
        />
        <Route
          path="/auth/reset-password"
          element={<AuthResetPasswordPage />}
        />
        <Route
          path="/portfolio"
          element={
            <Layout>
              <Portfolio />
            </Layout>
          }
        />
        <Route
          path="/portfolio/new"
          element={
            <Layout>
              <PortfolioNew />
            </Layout>
          }
        />
        <Route
          path="/portfolio/edit/:id"
          element={
            <Layout>
              <PortfolioEdit />
            </Layout>
          }
        />
        <Route
          path="/portfolio/:portfolioID/loan-list"
          element={
            <Layout>
              <PortfolioLoanList />
            </Layout>
          }
        />
        <Route
          path="/portfolio/loan/:loanID"
          element={
            <Layout>
              <PortfolioLoanDetails />
            </Layout>
          }
        />
        {/* Route RELATED TO disbursement */}
        <Route
          path="/disbursement/file-download"
          element={
            <Layout>
              <DisbursementDownloadFile />
            </Layout>
          }
        />
        <Route
          path="/disbursement/nach-file-download"
          element={
            <Layout>
              <DisbursementNACHFileDownload />
            </Layout>
          }
        />
        <Route
          path="/disbursement/loan-download"
          element={
            <Layout>
              <DisbursementLoanInfoFileDownload />
            </Layout>
          }
        />
        <Route
          path="/disbursement/upload"
          element={
            <Layout>
              <DisbursementUploadFile />
            </Layout>
          }
        />
        <Route
          path="/disbursement/upload-loan-file"
          element={
            <Layout>
              <DisbursementLoanFileUpload />
            </Layout>
          }
        />
        <Route
          path="/disbursement/upload-nach-file"
          element={
            <Layout>
              <DisbursementNACHFileUpload />
            </Layout>
          }
        />
        {/* Route RELATED TO Repayments */}
        <Route
          path="/repayment/bureau-file-download"
          element={
            <Layout>
              <RepaymentsBureauFileDownload />
            </Layout>
          }
        />
        <Route
          path="/repayment/dpd-report-download"
          element={
            <Layout>
              <RepaymentsDPDReportDownload />
            </Layout>
          }
        />{" "}
        <Route
          path="/repayment/emi-report-download"
          element={
            <Layout>
              <RepaymentsEMIReportDownload />
            </Layout>
          }
        />{" "}
        <Route
          path="/repayment/nach-file-download"
          element={
            <Layout>
              <RepaymentsNACHFileDownload />
            </Layout>
          }
        />{" "}
        <Route
          path="/repayment/upload-emi-status"
          element={
            <Layout>
              <RepaymentsEMIStatusUpload />
            </Layout>
          }
        />{" "}
        <Route
          path="/repayment/upload-repayment-file"
          element={
            <Layout>
              <RepaymentsRepaymentFileUpload />
            </Layout>
          }
        />{" "}
        <Route
          path="/repayment/upload-restructure"
          element={
            <Layout>
              <RepaymentsRestructureFileUpload />
            </Layout>
          }
        />
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<FourOFour />} />
      </Routes>
    </div>
  );
}

export default App;
