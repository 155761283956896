import React from "react";
import { PageHeader, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";

export default function UploadNACHFile() {
  return (
    <div className="tw-m-4 tw-p-4">
      <PageHeader
        className="site-page-header"
        title="Upload NACH Approval file"
      />
      <div className="tw-p-4">
        <Upload.Dragger>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
      </div>
    </div>
  );
}
