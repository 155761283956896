import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input, Typography, Divider } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import configFile from "../../../Utils/configFile.json";

export default function Signup() {
  const navigate = useNavigate();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const onSubmit = (values) => {
    console.log(values);
    navigate("/portfolio");
  };

  return (
    <div className="tw-bg-white">
      <div className="tw-flex tw-items-center tw-justify-center tw-mx-auto tw-pt-40">
        <Form
          name="basic"
          className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mx-auto"
          initialValues={{ remember: true }}
          size="middle"
          autoComplete="off"
          onFinish={onSubmit}
        >
          <Form.Item>
            <img
              src={configFile.logo}
              className="tw-w-20 tw-mx-auto tw-pb-4"
              alt="logo"
            />
            <Typography.Text type="secondary" className="">
              Create Your account!{" "}
            </Typography.Text>
          </Form.Item>

          <Form.Item
            name="name"
            className="tw-w-60 "
            rules={[
              { required: true, message: "Please input Your full Name!" }
            ]}
          >
            <Input
              placeholder="Enter Your Full Name"
              onChange={(e) => setName(e.target.value)}
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!"
              },
              {
                required: true,
                message: "Please input your E-mail!"
              }
            ]}
            className="tw-w-60 "
          >
            <Input
              placeholder="Enter Your email"
              onChange={(e) => setEmail(e.target.value)}
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>

          <Form.Item
            name="password"
            className="tw-w-60"
            rules={[{ required: true, message: "Confirm Your Password!" }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Confirm Your password!"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            className="tw-w-60"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!"
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                }
              })
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Please input Your password!"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <Button className="tw-w-60" type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
          <Divider plain>
            <Link to="/auth/login">
              <span
                className="tw-cursor-pointer tw-text-[#CCC] hover:tw-text-gray-800"
                style={{ color: "", fontWeight: "normal", fontSize: 14 }}
              >
                Already have account?
              </span>
            </Link>
          </Divider>
        </Form>
      </div>
    </div>
  );
}
