import React from "react";
import { Link } from "react-router-dom";
import { Space, Table, PageHeader, Divider, Typography } from "antd";
import data from "../../../../Utils/portfolio_mock_data.json";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text) => <p>{text}</p>
  },
  {
    title: "Actions",
    key: "actions",
    render: (_, record) => (
      <Space size="large" align="center" split={<Divider type="vertical" />}>
        <Link to={`/portfolio/${record.id}/loan-list`}>
          <Typography.Link>View All Loans</Typography.Link>
        </Link>
        <Typography.Link>View Portfolio</Typography.Link>
        <Link to={`/portfolio/edit/${record.id}`}>
          <Typography.Link>Edit Portfolio</Typography.Link>
        </Link>
      </Space>
    )
  }
];

export default function Index() {
  const [loading, setLoading] = React.useState(true);
  const [dataSource, setDataSource] = React.useState([]);

  React.useEffect(() => {
    setLoading(false);
    setDataSource(data);
  });

  return (
    <div className="tw-p-6">
      <PageHeader
        className="site-page-header "
        title="Portfolio"
        subTitle="Collection of all the loan portfolio "
      />
      <div className="">
        <Table loading={loading} columns={columns} dataSource={dataSource} />
      </div>
    </div>
  );
}
