import React from "react";
import { PageHeader, Form, Select, Space, Button, DatePicker } from "antd";

export default function DownloadBureauFile() {
  return (
    <div className="tw-p-4 tw-m-4">
      <PageHeader
        className="site-page-header"
        title="Download Bureau Reporting file"
      />
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
        <Form.Item label="Scheme">
          <Select placeholder="Enter Scheme">
            <Select.Option value=" Gyandhan"> Gyandhan</Select.Option>
            <Select.Option value="Razorpay">Razorpay</Select.Option>
            <Select.Option value="GoRupee">GoRupee</Select.Option>
            <Select.Option value="Instamojo">Instamojo</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Product Type">
          <Select placeholder="Loan Type">
            <Select.Option value=" PL"> PL</Select.Option>
            <Select.Option value="CL">CL</Select.Option>
            <Select.Option value="ML">ML</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Disbursement Date">
          <DatePicker.RangePicker />
        </Form.Item>
        <Form.Item label="Reporting Date">
          <DatePicker />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
          <Space size="large">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button htmlType="button">Reset</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
