import React from "react";
import {
  PageHeader,
  Form,
  Select,
  Space,
  Button,
  DatePicker,
  TimePicker
} from "antd";

const loanStage = [
  " Lead Generated",
  "Document Check Pending",
  "Credit Double Check",
  "Pending",
  "Super Admin Loan Approval",
  "Super Admin Loan Update",
  "Approval",
  "Super Admin Loan",
  "Restructure Approval",
  "Disbursement Pending",
  "Failed Loan",
  "Loan Ongoing Monitoring",
  "Loan Ongoing",
  "Loan Complete",
  "Super Admin Account",
  "Rejected",
  "NACH Flag"
];

export default function DownloadDisbursementFile() {
  return (
    <div className="tw-m-4 tw-p-4">
      <div className="tw-p-4 tw-m-4">
        <PageHeader
          className="site-page-header"
          title="Download Disbursement File"
        />
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
          <Form.Item label="Scheme">
            <Select placeholder="Enter Scheme">
              <Select.Option value=" Gyandhan"> Gyandhan</Select.Option>
              <Select.Option value="Razorpay">Razorpay</Select.Option>
              <Select.Option value="GoRupee">GoRupee</Select.Option>
              <Select.Option value="Instamojo">Instamojo</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Product Type">
            <Select placeholder="Loan Type">
              <Select.Option value=" PL"> PL</Select.Option>
              <Select.Option value="CL">CL</Select.Option>
              <Select.Option value="ML">ML</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Disbursement Start Date">
            <DatePicker />
          </Form.Item>
          <Form.Item label="Created Before Date">
            <DatePicker />
          </Form.Item>
          <Form.Item label="Created Before Date">
            <TimePicker use12Hours format="h:mm a" />
          </Form.Item>
          <Form.Item label="Created After Date">
            <DatePicker />
          </Form.Item>
          <Form.Item label="Created After Time">
            <TimePicker use12Hours format="h:mm a" />
          </Form.Item>
          <Form.Item label="Select Stage">
            <Select mode="multiple" placeholder="Multiple Select Stage">
              {loanStage.map((stage) => (
                <Select.Option value={stage}> {stage}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button htmlType="button">Reset</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
